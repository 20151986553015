.table {
    width: 100%;
}
.tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: .6rem 1rem;
    width: 100%;
    flex-wrap: wrap;
}